import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { i18n, useTranslation } from "next-i18next"
import Head from "next/head"
import Script from "next/script"
import BreadCrumb from "@/components/Default/BreadCrumb"
import AemGrid from "@/components/AemGrid"
import { breadCrumbApplicationJasonLdString } from "@/utils/BreadCrumbApplicationJsonLd"
import {
  CORE_GLOBAL_CONFIG_URL,
  footerUrl,
  headerUrl,
  previewContentPrefix,
  publicWebsiteUrl,
} from "@/constants"
import { aemAxiosInstance, aemPreviewAxiosInstance } from "@/constants/api"
import GlobalPageHeader from "@/components/Shared/GlobalPage/GlobalPageHeader"
import GlobalPageFooter from "@/components/Shared/GlobalPage/GlobalPageFooter"
import {
  getClienIP,
  getScriptsAndStyles,
  getAnalyticsScript,
  getAccessToken,
  filterBackEndJson,
  removeAndCleanLink,
  performRedirect,
  generateCanonicalUrl,
  extractScriptsFromDOM,
} from "@/utils/helper"
import { getCartCount, getAriaLabel } from "@/utils/cart"
import {
  selectGenericState,
  setBrandLogoImg,
  closeMenu,
  openMenu,
  setActiveMainCategory,
  setRegionNav,
  setGlobalScripts,
  setGlobalStyles,
  setAuthNav,
  setDatalayerPageName,
  setGbhDataLayerCustom,
} from "@/store/features/genericSlice"
import usePageAuth from "@/hooks/usePageAuth"
import { selectCartState } from "@/store/features/cartSlice"
import { getDataLayerObj } from "@/utils/helper"
import ProductList from "@/components/ProductList/v2/ProductList"
import {
  getListFromClient,
  getListFromServer,
  getPlpParams,
  formatListingData,
} from "@/utils/product"
import { setPlpData } from "@/store/features/productListSlice"
import useIsSsr from "@/hooks/useIsSsr"
import PLPLoader from "@/components/ProductList/v2/PLPLoader"
import styles from "@/components/ProductList/v2/ProductListView/index.module.scss"
import productTileStyles from "@/components/ProductList/v2/ProductTile/index.module.scss"
import UpSellInstallServices from "@/components/Shared/UpsellInstallServices"
import ProductAccordion from "@/components/ProductDetail/v3/ProductAccordion"

/**
 * renders home page
 * @return {JSX.Element}
 * @param {object} props The props.
 */
const Listing = props => {
  const {
    tmpData: data,
    headertmp: header,
    footertmp: footer,
    ip,
    adobeAnalytic,
    globalScript,
    footerScript,
    analyticsData,
    datalayerPageName,
    plpData,
    gbhDataLayerCustom,
    canonicalUrl = "",
    seoPageScript = "",
    breadCrumbApplicationJSonLd = "",
  } = props
  const { t } = useTranslation("common")
  const language = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const isSsr = useIsSsr()
  const { cartUrl } = useSelector(selectCartState)
  const [pageRedirect, setPageRedirect] = useState("")
  const [seoScripts, setSeoScript] = useState([])
  const { redirectTarget: { page: { path = "" } = {} } = {} } = data
  const {
    brandLogoImg,
    menu: { isOpen, mainCategoryActive, isRegionOpen, isAuthNavOpen },
  } = useSelector(selectGenericState)
  useEffect(() => {
    if (ip) sessionStorage.setItem("true-ip", ip)
  }, [ip])
  usePageAuth()
  const cartCount = getCartCount()
  const ariaLabel = getAriaLabel(t, cartCount)
  useEffect(() => {
    getScriptsAndStyles(footer)
  }, [footer])
  useEffect(() => {
    getAnalyticsScript(adobeAnalytic)
  }, [adobeAnalytic])
  useEffect(() => {
    getDataLayerObj(analyticsData)
  }, [analyticsData])
  useEffect(() => {
    if (path) {
      setPageRedirect(removeAndCleanLink(path))
    }
  }, [path])
  useEffect(() => {
    if (!isSsr && pageRedirect) {
      router.push(pageRedirect)
    }
  }, [isSsr, pageRedirect])
  useEffect(() => {
    // if (isSsr) return
    dispatch(setDatalayerPageName(datalayerPageName))
    dispatch(setGbhDataLayerCustom(gbhDataLayerCustom))
    if (plpData) {
      dispatch(
        setPlpData({ ...plpData, status: "succeeded", initialLoad: true })
      )
    }
    return () => {
      dispatch(setDatalayerPageName(""))
      dispatch(setGbhDataLayerCustom(""))
    }
  }, [datalayerPageName, gbhDataLayerCustom, plpData])
  const toggleMenu = () => {
    if (isOpen) {
      dispatch(closeMenu())
      dispatch(setAuthNav(false))
    } else {
      dispatch(openMenu())
    }
  }
  const redirect = () => {
    router.push(`${cartUrl}`)
  }
  const eventHandler = {
    store: {
      brandLogoImg: brandLogoImg,
      isOpen,
      mainCategoryActive,
      isRegionOpen,
      isAuthNavOpen,
      cartCount,
      ariaLabel,
      cartUrl,
      plp: { ...plpData, status: "succeeded" },
    },
    callbacks: {
      setBrandLogoImg: path => {
        dispatch(setBrandLogoImg(path))
      },
      closeMenu: () => {
        dispatch(closeMenu())
      },
      setMainCategory: payload => {
        dispatch(setActiveMainCategory(payload))
      },
      toggleMenu: () => {
        toggleMenu()
      },
      setRegionNav: active => {
        dispatch(setRegionNav(active))
      },
      setAuthNav: active => {
        dispatch(setAuthNav(active))
      },
      setGlobalScripts: item => {
        dispatch(setGlobalScripts(item))
      },
      setGlobalStyles: item => {
        dispatch(setGlobalStyles(item))
      },
      onClick: () => {
        redirect()
      },
    },
  }

  useEffect(() => {
    if (seoPageScript) {
      extractSeoPageScript(seoPageScript)
    }
  }, [seoPageScript])

  const extractSeoPageScript = (scriptContent = "") => {
    const scriptList = []
    const doc = new DOMParser().parseFromString(scriptContent, "text/html")
    extractScriptsFromDOM(doc, item => scriptList.push(item))
    setSeoScript(scriptList)
  }

  return (
    <>
      <Head>
        {canonicalUrl ? (
          <link
            rel="canonical"
            href={
              language?.i18n?.language === "fr"
                ? canonicalUrl?.replace("/en/", "/fr/")
                : canonicalUrl
            }
          />
        ) : null}
        {breadCrumbApplicationJSonLd && (
          <script type="application/ld+json">
            {breadCrumbApplicationJSonLd}
          </script>
        )}
      </Head>
      <>
        <GlobalPageHeader
          header={header}
          title={data.title}
          globalScripting={globalScript}
        />
        <style jsx global>
          {`
            @media print {
              @page {
                size: A4 portrait !important;
              }
            }
          `}
        </style>
        {seoScripts.map((script, i) =>
          !script.src && script.innerHTML ? (
            <Script
              key={`seoscript-${i}`}
              id={`seoscript-${i}`}
              type={script.type}
              data-script-purpose="seo"
            >
              {`${script.innerHTML}`}
            </Script>
          ) : null
        )}
        <div id={data.id} className={data.cssClassNames}>
          <AemGrid
            data={data}
            count={0}
            eventHandler={eventHandler}
            breadCrumb={(data, fullData) => (
              <BreadCrumb data={data} fullData={fullData} plpPage={true} />
            )}
            productAccordion={(data, fullData) => (
              <ProductAccordion data={data} fullData={fullData} />
            )}
            productList={(data, eventHandler) => (
              <ProductList data={data} eventHandler={eventHandler} />
            )}
            productListFiller={() => (
              <div
                className={`${styles.productListWrapper} ${productTileStyles.productTileWrapper}`}
              >
                <PLPLoader />
              </div>
            )}
            upSellContainer={data => <UpSellInstallServices authData={data} />}
          />
        </div>
        <GlobalPageFooter footer={footer} footerScripting={footerScript} />
      </>
    </>
  )
}
/**
 *
 * @param {*} context
 * @return {Object}
 */
export async function getServerSideProps({
  req,
  res,
  locale,
  query,
  resolvedUrl,
  params,
}) {
  await i18n?.reloadResources()
  const isPreviewMode = req.cookies["aem-author-preview-mode"]
  const isServer = typeof window === "undefined"
  let plpData = {}
  let accessToken = null
  const ip = getClienIP(req)

  let urlPath = req.url.split("?")[0] ?? ""
  if (req.url.indexOf(".json") > -1 && req.url.indexOf("_next") > -1) {
    // urlPath = resolvedUrl.split("?")[0]
    urlPath = decodeURIComponent(resolvedUrl).split("?")[0].split("|").join("/")

    if (urlPath.indexOf("SPLITVAL") > -1) {
      urlPath = urlPath.split("SPLITVAL")[1]
    }
  }

  const { redirectUrl = "" } = performRedirect(urlPath, locale) ?? {}
  if (redirectUrl) {
    return {
      redirect: {
        destination: redirectUrl,
        permanent: true,
      },
    }
  }

  if (!isServer) {
    accessToken = getAccessToken()?.token
  }
  let data
  try {
    const response = isPreviewMode
      ? await aemPreviewAxiosInstance({
          url: `${previewContentPrefix}/${locale}${urlPath}.model.json`,
          method: "GET",
        })
      : await aemAxiosInstance({
          url: `/${locale}${urlPath}.model.json`,
          method: "GET",
        })
    data = response?.data
  } catch (err) {
    return {
      notFound: true,
    }
  }

  const globalScript = data?.globalScript
  const footerScript = data?.footerScript
  const topHeadScript = data?.topHeadScript ? data?.topHeadScript : null
  const canonicalUrl = generateCanonicalUrl(req?.url, locale, publicWebsiteUrl)
  const analyticsScriptClass = data?.analyticsScriptClass
    ? data?.analyticsScriptClass
    : null

  const gbhDataLayerCustom = {}
  if (data?.datalayerPageName) {
    gbhDataLayerCustom.datalayerPageName = data?.datalayerPageName
  }
  if (data?.eventPageType) {
    gbhDataLayerCustom.eventPageType = data?.eventPageType
  }
  if (data?.pageType) {
    gbhDataLayerCustom.pageType = data?.pageType
  }
  if (
    !data?.pageComponentProperties?.description &&
    data?.pageComponentProperties?.dataLayer &&
    data?.pageComponentProperties?.dataLayer[data?.pageComponentProperties?.id]
  ) {
    gbhDataLayerCustom.dataLayer =
      data?.pageComponentProperties?.dataLayer[
        data?.pageComponentProperties?.id
      ]
  }
  const categoryKey = getPlpParams(data, "categoryKey")
  const { data: header } = await aemAxiosInstance({
    url: `${data.headerExpFrg ? data.headerExpFrg : headerUrl}.model.json`,
    method: "GET",
  })
  const { data: footer } = await aemAxiosInstance({
    url: `${data.footerExpFrg ? data.footerExpFrg : footerUrl}.model.json`,
    method: "GET",
  })

  const { data: configData = {} } = await aemAxiosInstance({
    url: `${CORE_GLOBAL_CONFIG_URL}?caConfig=true&path=/${locale}${urlPath}`,
    method: "GET",
  })

  const { general: { siteName = "" } = {} } = configData

  // if (urlPath.length == 1) {
  if (isServer) {
    try {
      const data = await getListFromServer(categoryKey, "GST", siteName)
      plpData = await formatListingData(data, true)
    } catch (error) {
      plpData = null
    }
  } else {
    try {
      const data = await getListFromClient(
        categoryKey,
        "GST",
        accessToken,
        siteName
      )
      plpData = await formatListingData(data)
    } catch (error) {
      plpData = null
    }
  }
  // }
  const analyticsData = data?.data
  let tmpData = {}
  const adobeAnalytic = data?.adobeAnalytic || null
  if (data.pageComponentProperties) {
    tmpData = data.pageComponentProperties
  } else {
    tmpData = data
  }
  let bredCrumData
  try {
    bredCrumData = await breadCrumbApplicationJasonLdString(data)
  } catch (error) {
    return false
  }
  tmpData = filterBackEndJson(tmpData)
  const headertmp = filterBackEndJson(header)
  const footertmp = filterBackEndJson(footer)
  const datalayerPageName = data?.datalayerPageName
  const metaContent = data?.metaContent ?? ""
  const seoPageScript = data?.seoPageScript ?? ""
  const breadCrumbApplicationJSonLd = bredCrumData ? bredCrumData : null

  return {
    props: {
      ip,
      tmpData,
      headertmp,
      footertmp,
      adobeAnalytic,
      globalScript,
      footerScript,
      topHeadScript,
      analyticsData,
      analyticsScriptClass,
      datalayerPageName,
      plpData,
      gbhDataLayerCustom,
      urlPath,
      canonicalUrl,
      metaContent,
      seoPageScript,
      breadCrumbApplicationJSonLd,
      ...(await serverSideTranslations(locale, ["common"])),
    },
  }
}
export default Listing
